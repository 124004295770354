const headings = document.querySelectorAll(".experience h3");

const resizeHeadings = () => {
  let maxHeight = 0;

  for (i = 0; i < headings.length; i++) {
    headings[i].style.height = "";
    if (headings[i].clientHeight > 0) {
      maxHeight = headings[i].clientHeight;
    }
  }

  for (i = 0; i < headings.length; i++) {
    headings[i].style.height = maxHeight + "px";
  }
};
resizeHeadings();

window.addEventListener("resize", resizeHeadings);
