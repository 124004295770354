import "element-closest";

const body = document.querySelector("body");
const teamModal = document.querySelector("#teamModal");
const teamMembers = document.querySelector(".team .members");

let toggleModal = modal => {
  modal.classList.toggle("modal--open");
  body.classList.toggle("modal--open");
};

teamMembers.addEventListener("click", e => {
  if (e.target.closest(".member")) {
    toggleModal(teamModal);
  }
});

teamModal.addEventListener("click", e => {
  if (e.target.closest(".modal__close") || e.target === teamModal) {
    toggleModal(teamModal);
  }
});
