const body = document.querySelector("body");
const raf = window.requestAnimationFrame;
let scrollTop;

let checkScroll = () => {
  if (scrollTop === window.pageYOffset) {
    raf(checkScroll);
    return false;
  } else {
    scrollTop = window.pageYOffset;
  }

  if (scrollTop > 100) {
    body.classList.add("scrolled");
  } else {
    body.classList.remove("scrolled");
  }

  raf(checkScroll);
};
checkScroll();
