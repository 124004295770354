import Flickity from "flickity";

const testimonialsCarousel = document.querySelector(".testimonials__carousel");
const testimonialsFlickity = new Flickity(testimonialsCarousel, {
  cellAlign: "left",
  contain: true,
  pageDots: false,
  arrowShape: {
    x0: 10,
    x1: 60,
    y1: 50,
    x2: 65,
    y2: 45,
    x3: 20
  }
});
